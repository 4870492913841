import { mutateItems } from "@/api/items/useItemQuery";
import { ItemsForm } from "@/features/item/components/form/itemsStepperForm/ItemsForm";
import { OperationBomList } from "@/features/item/components/form/itemsStepperForm/OperationBomList";
import { OperationsForm } from "@/features/item/components/form/itemsStepperForm/OperationsForm";
import { OperationsList } from "@/features/item/components/form/itemsStepperForm/OperationsList";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { Header } from "@/features/ui/view/header";
import { isEmpty } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Group, Space, Stepper } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ItemsGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerOperationsInner,
  MasterApiItemsOneStepCreatePostRequest,
  MasterApiItemsPostRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

export interface itemFormProps {
  items: Partial<ItemsGet200ResponseRowsInner>;
  operations: any;
  operationBoms: any;
}

export interface OperationFormProps {
  operationRows: Array<ItemsGet200ResponseRowsInnerOperationsInner>;
}
export function ItemsCreateForm() {
  const [active, setActive] = useState(0);
  const { closeModal } = useModal();

  const itemForm = useForm<Partial<ItemsGet200ResponseRowsInner>>({
    initialValues: {
      code: undefined,
      itemType: undefined,
      name: undefined,
      itemCategoryName: undefined, // 품목 카테고리 (분류)
      abstractItemCode: undefined, // 품목군 코드
      description: undefined,
      unitText: undefined,
      usesLotName: undefined,
      usesLotExpiration: undefined,
      additional: {},
    },
  });
  const operationForm = useForm<Partial<OperationFormProps>>({
    initialValues: {
      operationRows: [
        {
          seq: undefined, // 공정순서
          routingCode: undefined, // 라우팅코드
          code: undefined, // 공정코드
          operationType: undefined, // 공정유형 (공정 타입)
          consumeType: undefined, // 소모 시점
          moldCode: undefined, // 금형코드
          operationBoms: [
            {
              operationCode: undefined, // 공정코드
              itemCode: undefined,
              quantity: undefined,
              seq: undefined,
              item: {
                unit_text: undefined,
              }
            },
          ], // 공정 BOM
        },
      ],
    },
  });

  // 1페이지 -> 2페이지 이동 조건
  const isValidStep1: boolean =
    { ...itemForm.getInputProps("code") }.value &&
    { ...itemForm.getInputProps("itemType") }.value &&
    { ...itemForm.getInputProps("name") }.value;

  // 2페이지 -> 3페이지 이동 조건
  const operationsData = {
    ...operationForm.getInputProps("operationRows"),
  }.value;




  const isValidStep2: boolean = operationsData.every((el: any) => {
    return (
      !!el.seq &&
      !!el.routingCode &&
      !!el.code &&
      !!el.operationType &&
      !!el.consumeType
    );
  });

  // 3페이지 -> 4페이지 이동 조건
  const operationsBomData: boolean = operationsData.map(
    (item: any) => item.operationBoms
  );

  const isValidStep3 = shouldReturnTrue(operationsBomData);

  function shouldReturnTrue(data: any): any {
    const flattenedData = data.flat(2);

    // 모든 객체에 대해 seq, itemCode, quantity 값이 전부 있거나 전부 없는지 확인합니다.
    const allValuesAreConsistent = flattenedData.every((obj: any) => {
      const hasValues =
        obj.seq !== undefined &&
        obj.itemCode !== undefined &&
        obj.quantity !== undefined;
      const allEmpty =
        isEmpty(obj.seq) && isEmpty(obj.itemCode) && isEmpty(obj.quantity);
      return hasValues || allEmpty;
    });

    return allValuesAreConsistent;
  }

  const nextStep = () =>
    setActive((current) => {
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const queryClient = useQueryClient();
  const { mutate: createOneStepMutate } = useMutation(
    (params: MasterApiItemsOneStepCreatePostRequest) =>
      mutateItems.onstep(params).mutationFn(params as MasterApiItemsOneStepCreatePostRequest | any), {
    onSuccess: () => {
      queryClient.invalidateQueries('items');
    }
  }
  );
  const { mutate: createMutate } = useMutation(
    (params: MasterApiItemsPostRequest) =>
      mutateItems.create(params).mutationFn(params as MasterApiItemsPostRequest | any), {
    onSuccess: () => {
      queryClient.invalidateQueries('items');
    }
  }
  );

  const saveForm = () => {
    const operationData =
      operationForm.values.operationRows &&
      operationForm?.values?.operationRows.map((row) => {
        // 새로운 객체 생성
        const newRow = {
          code: row.code,
          routingCode: row.routingCode,
          operationType: row.operationType,
          consumeType: row.consumeType,
          seq: Number(row.seq),
          moldCode: row.moldCode,
          operationBoms:
            row.operationBoms &&
            row.operationBoms
              .filter((bom) => !isEmpty(bom.itemCode) && !isEmpty(bom.quantity))
              .map((bom) => ({
                quantity: bom.quantity,
                itemCode: bom.itemCode,
              })),
        };
        return newRow;
      });

    let formData;
    // 품목유형이 '원/부자재'인 경우
    if (itemForm.values.itemType === "MATERIAL" || itemForm.values.itemType === "SUBSIDIARY") {
      formData = {
        code: itemForm.values.code,
        itemType: itemForm.values.itemType,
        name: itemForm.values.name,
        itemCategoryName: itemForm.values.itemCategoryName,
        abstractItemCode: itemForm.values.abstractItemCode,
        usesLotName: itemForm.values.usesLotName,
        usesLotExpiration: itemForm.values.usesLotExpiration,
        spec: itemForm.values.spec,
        width: itemForm.values.width,
        height: itemForm.values.height,
        depth: itemForm.values.depth,
        texture: itemForm.values.texture,
        unitText: itemForm.values.unitText,
      };
      createMutate(
        {
          itemsGetRequest: {
            ...formData,
            code: formData.code ?? "",
            name: formData.name ?? "",
          },
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              customAlert(
                "품목 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "품목 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
            itemForm.reset();
            closeModal(() => {
              setActive(0);
            });
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '품목코드' }), '품목 생성 실패', 'red')
          }
        }
      );
    } else {
      // 품목유형이 '제품'인 경우
      formData = {
        code: itemForm.values.code,
        itemType: itemForm.values.itemType,
        name: itemForm.values.name,
        itemCategoryName: itemForm.values.itemCategoryName,
        abstractItemCode: itemForm.values.abstractItemCode,
        operations: operationData,
        usesLotName: itemForm.values.usesLotName,
        usesLotExpiration: itemForm.values.usesLotExpiration,
        spec: itemForm.values.spec,
        width: itemForm.values.width,
        height: itemForm.values.height,
        depth: itemForm.values.depth,
        texture: itemForm.values.texture,
        unitText: itemForm.values.unitText,
      };
      createOneStepMutate(
        { itemsOneStepCreatePostRequest: formData },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              customAlert(
                "품목 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "품목 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
            itemForm.reset();
            operationForm.reset();
            closeModal(() => {
              setActive(0);
            });
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '품목코드' }), '품목 생성 실패', 'red')
          }
        }
      );
    }
  };
  const StepButtons = () => {
    return (
      <>
        {itemForm.values.itemType === "MATERIAL" || itemForm.values.itemType === "SUBSIDIARY" ? (
          <>
            <Group position="center" mt="xl" grow>
              <Button
                variant="outline"
                onClick={prevStep}
                disabled={active === 0}
              >
                이전
              </Button>
              {active === 0 && (
                <Button
                  variant="outline"
                  onClick={nextStep}
                  disabled={!isValidStep1}
                >
                  다음
                </Button>
              )}
              {active === 1 && <Button onClick={saveForm}>등록</Button>}
            </Group>
            <Space h="xl" />
          </>
        ) : (
          <>
            <Group position="center" mt="xl" grow>
              <Button
                variant="outline"
                onClick={prevStep}
                disabled={active === 0}
              >
                이전
              </Button>
              {active === 0 && (
                <Button
                  variant="outline"
                  onClick={nextStep}
                  disabled={!isValidStep1}
                >
                  다음
                </Button>
              )}
              {active === 1 && (
                <Button
                  variant="outline"
                  onClick={nextStep}
                  disabled={!isValidStep2}
                >
                  다음
                </Button>
              )}
              {active === 2 && (
                <Button
                  variant="outline"
                  onClick={nextStep}
                  disabled={!isValidStep3}
                >
                  다음
                </Button>
              )}
              {active === 3 && <Button onClick={saveForm}>등록</Button>}
            </Group>
            <Space h="xl" />
          </>
        )}
      </>
    );
  };

  return (
    <ItemFormWrapper>
      <form>
        {itemForm.values.itemType === "MATERIAL" || itemForm.values.itemType === "SUBSIDIARY" ? (
          <Stepper active={active} breakpoint="sm">
            <Stepper.Step
              label="기본정보 등록"
              description="기본 정보를 등록합니다."
            >
              <StepButtons />
              <ItemsForm formData={itemForm} />
            </Stepper.Step>

            <Stepper.Step
              label="확인"
              description="최종적으로 생성될 품목을 미리 확인합니다."
            >
              <StepButtons />
              <Header itemData={itemForm.values} />
              <Space h="sm" />
            </Stepper.Step>
          </Stepper>
        ) : (
          <Stepper active={active} breakpoint="sm">
            <Stepper.Step
              label="기본정보 등록"
              description="기본 정보를 등록합니다."
            >
              <StepButtons />
              <ItemsForm formData={itemForm} />
            </Stepper.Step>
            <Stepper.Step
              label="공정정보 등록"
              description="공정 정보를 등록합니다."
            >
              <StepButtons />
              <Header itemData={itemForm.values} />
              <Space h="sm" />
              <OperationsForm formData={operationForm} />
            </Stepper.Step>

            <Stepper.Step
              label="공정별 BOM 등록"
              description="공정별 BOM을 등록합니다."
            >
              <StepButtons />
              <Header itemData={itemForm.values} />
              <Space h="sm" />
              <OperationsList formData={operationForm} />
            </Stepper.Step>

            <Stepper.Step
              label="확인"
              description="최종적으로 생성될 품목을 미리 확인합니다."
            >
              <StepButtons />
              <Header itemData={itemForm.values} />
              <OperationBomList formData={operationForm.values} />
            </Stepper.Step>
          </Stepper>
        )}
      </form>
    </ItemFormWrapper>
  );
}

const ItemFormWrapper = styled.div`
  // width: 100%;
  width: 1200px;
`;
