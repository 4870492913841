import ProtectedRoute from "@/features/Router/ProtectedRoute";
import { Layout } from "@/features/layout/Template";
import ModalExample from "@/features/modal/example";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { AbstractItemPage } from "@/pages/abstractItem";
import { CommonPage } from "@/pages/common";
import { MainDashboard } from "@/pages/dashboard";
import { Defects } from "@/pages/defects";
import { DeliveryStatusPage } from "@/pages/deliveryStatus";
import { DowntimeReasonsPage } from "@/pages/downtimeReasons";
import { Equipment } from "@/pages/equipment";
import { InspectSpecificationPage } from "@/pages/inspect";
import { InspectionRecordsPage } from "@/pages/inspectionRecords";
import { InventoryPage } from "@/pages/inventory";
import { Item } from "@/pages/item";
import { ItemCategoryPage } from "@/pages/itemCategory";
import { LedgerAllPage } from "@/pages/ledgersAll";
import { LocationPage } from "@/pages/location";
import Login from "@/pages/login";
import { FormExample } from "@/pages/modalExample/form";
import WizardForm from "@/pages/modalExample/wizard";
import { MoldPage } from "@/pages/mold";
import { MoldStockPage } from "@/pages/moldStock";
import { MonitoringPage } from "@/pages/monitoring";
import { Operation } from "@/pages/operation";
import { OperationOutsourcePage } from "@/pages/operationOutsource";
import { OverViewPage } from "@/pages/overview";
import { PurchaseOrderItemsPage } from "@/pages/purchaseOrderItems";
import { Routings } from "@/pages/routings";
import SignUp from "@/pages/signUp";
import { Site } from "@/pages/site";
import { Users } from "@/pages/users";
import { WipInventoryPage } from "@/pages/wipInventory";
import { WmsLogsPage } from "@/pages/wmsLogs";
import { WorkByEquipment } from "@/pages/workByEquipment";
import { WorkByProductionPlan } from "@/pages/workByProductionPlan";
import { WorkByRows } from "@/pages/workByRows";
import { WorkLogPage } from "@/pages/workLog";
import { useCheckAuth } from "@/utils/checkAuth";

import { Route, Routes, useLocation } from "react-router-dom";


const RoutePaths = [
  { path: "/dashboard", element: <MainDashboard /> },
  { path: "/items", element: <Item /> },
  { path: "/equipment", element: <Equipment /> },
  { path: "/defects", element: <Defects /> },
  { path: "/routings", element: <Routings /> },
  { path: "/users", element: <Users /> },
  { path: "/operation", element: <Operation /> },
  { path: "/site", element: <Site /> },
  { path: "/location", element: <LocationPage /> },
  { path: "/operation-outsource", element: <OperationOutsourcePage /> },
  { path: "/abstract-item", element: <AbstractItemPage /> },
  { path: "/item-category", element: <ItemCategoryPage /> },
  { path: "/work-by-equipment", element: <WorkByEquipment /> },
  { path: "/work-by-production-plan", element: <WorkByProductionPlan /> },
  { path: "/work-by-row", element: <WorkByRows /> },
  { path: "/wizard", element: <WizardForm /> },
  { path: "/form", element: <FormExample /> },
  { path: "/modal-multi", element: <ModalExample /> },
  { path: "/production-plan-with-works-form", element: <ProductionPlanWithWorksForm /> },
  { path: "/inventory", element: <InventoryPage /> },
  { path: "/wipInventory", element: <WipInventoryPage /> },
  { path: "/ledgersAll", element: <LedgerAllPage /> },
  { path: "/mold", element: <MoldPage /> },
  { path: "/moldStock", element: <MoldStockPage /> },
  { path: "/inspectSpecification", element: <InspectSpecificationPage /> },
  { path: "/downtimeReasons", element: <DowntimeReasonsPage /> },
  { path: "/work-log", element: <WorkLogPage /> },
  { path: "/overview", element: <OverViewPage /> },
  { path: "/monitoring", element: <MonitoringPage /> },
  { path: "/purchaseOrderItems", element: <PurchaseOrderItemsPage /> },
  { path: "/deliveryStatus", element: <DeliveryStatusPage /> },
  { path: "/wmsLogs", element: <WmsLogsPage /> },
  { path: "/inspectionRecords", element: <InspectionRecordsPage /> },
  { path : "/system", element: <CommonPage /> },
  { path : "/system-logs", element: <CommonPage /> },
  { path : "/system-parameter", element: <CommonPage /> },
  { path : "/common", element: <CommonPage /> },
  { path : "/authority", element: <CommonPage /> },
  { path : "department", element: <CommonPage /> },
  { path : "/ALCmodel", element: <CommonPage /> },
  { path : "/bom", element: <CommonPage /> },
  { path : "/bomList", element: <CommonPage /> },
  { path : "/file", element: <CommonPage /> },
  { path : "/work-complete", element: <CommonPage /> },
  { path : "/downtimeReasons-log", element: <CommonPage /> },
  { path : "/receipt", element: <CommonPage /> },
  { path : "/barcodeLift", element: <CommonPage /> },
  { path : "/barcodeMerge", element: <CommonPage /> },
  { path : "/lackSafety", element: <CommonPage /> },
  { path : "/inventoryLife", element: <CommonPage /> },
  { path : "/shipment-request", element: <CommonPage /> },
  { path : "/receipt", element: <CommonPage /> },
  { path : "/shipment-status", element: <CommonPage /> },
  { path : "/shipment-return", element: <CommonPage /> },
  { path : "/defectsList", element: <CommonPage /> },
  { path : "/work-standard", element: <CommonPage /> },
  { path : "/changePoint", element: <CommonPage /> },
  { path : "/changePointList", element: <CommonPage /> },
  { path : "/receivingInspection", element: <CommonPage /> },
  { path : "/productionInspection", element: <CommonPage /> },
  { path : "/peripheral", element: <CommonPage /> },
  { path : "/tool", element: <CommonPage /> },
  { path : "/leadtime", element: <CommonPage /> },
  { path : "/qualityDefect", element: <CommonPage /> },
  { path : "/manhour", element: <CommonPage /> },
  { path : "/leadtimeOrder", element: <CommonPage /> },
  { path : "/powerusage", element: <CommonPage /> },
  { path : "/uph", element: <CommonPage /> },
  { path : "/rateOperation", element: <CommonPage /> },
  { path : "/analysisMonitoring", element: <CommonPage /> },
  { path : "/errorView", element: <CommonPage /> },
  { path : "/maintenance", element: <CommonPage /> },
  { path : "/maintenanceList", element : <CommonPage /> },
  { path : "/maintenanceComplete", element : <CommonPage /> },
  { path : "/equipmentProblem", element : <CommonPage /> },
  { path : "/dailyRegister", element : <CommonPage /> },
  { path : "/dailyList", element : <CommonPage /> },
  { path : "/output", element : <CommonPage /> },
  { path : "/error", element : <CommonPage /> },
  { path : "/power", element : <CommonPage /> },
  { path : "/idleTime", element : <CommonPage /> },
  { path : "/workTime", element : <CommonPage /> },
  { path : "/order", element : <CommonPage /> },
  { path : "/purchaseOrderItems-gyeongnam", element : <CommonPage /> },
  { path : "/deliveryStatus-gyeongnam", element : <CommonPage /> },
  { path : "/deliveryList-company", element : <CommonPage /> },
  { path : "/deliveryList-gyeongnam", element : <CommonPage /> },
  { path : "/order-gyeongnam", element : <CommonPage /> },
  { path : "/purchaseOrderItems-supplier", element : <CommonPage /> },
  { path : "/deliveryStatus-supplier", element : <CommonPage /> },
  { path : "/deliveryList-gyeongnam", element : <CommonPage /> },
  { path : "/deliveryList-supplier", element : <CommonPage /> },
  { path : "/shipment-request_supplier", element : <CommonPage /> },
  { path : "/receipt_supplier", element : <CommonPage /> },
  { path : "/shipment-status_supplier", element : <CommonPage /> },
  { path : "/label_supplier", element : <CommonPage /> },
];

function RoutesConfig() {
  const location = useLocation();
  const auth = useCheckAuth();

  return (
    <Routes location={location}>
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/dashboard"}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/"}>
              <SignUp />
            </ProtectedRoute>
          }
        />
        {RoutePaths.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                {route.element}
              </ProtectedRoute>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default RoutesConfig;
