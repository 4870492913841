import { MenuTemplate } from "@/features/ui/Menu";
import { useLogout } from "@/hooks";
import {
  ActionIcon,
  Button,
  Container,
  Group,
  Title,
  useMantineColorScheme
} from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons-react";
import classes from "./Header.module.css";


export function HeaderMenu() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const logout = useLogout();

  return (
    <header className={classes.header}>
      <Container size="md" maw={"100%"}>
        <div className={classes.inner}>
          <Title order={3}>SIZL MESv3</Title>

          <Group spacing="md">
            <MenuTemplate />
            <Button onClick={logout} variant="subtle" color="gray">
              Logout
            </Button>
            <ActionIcon
              variant="outline"
              color={dark ? "yellow" : "blue"}
              onClick={() => toggleColorScheme()}
              title="Toggle color scheme"
            >
              {dark ? (
                <IconSun size="1.1rem" />
              ) : (
                <IconMoonStars size="1.1rem" />
              )}
            </ActionIcon>
          </Group>
          {/* <Burger opened={opened} onClick={toggle} size="sm" /> */}
        </div>
      </Container>
    </header>
  );
}
